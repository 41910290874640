
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DomainModule } from '@/modules/domain/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { NavigationModule } from '@/modules/navigation/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { CarouselRecentsTab, CarouselType, IOverviewCarouselItem } from '../../../types'
import CarouselEmptyState from './carousel-empty-state.vue'
import CarouselHeader from './carousel-header.vue'
import CarouselItem from './carousel-item.vue'
import CarouselNavigationItem, { NavigationItem } from './carousel-navigation-item.vue'

@Component({
  components: {
    CarouselEmptyState,
    CarouselHeader,
    CarouselItem,
    CarouselNavigationItem
  },
  name: 'OverviewCarousel'
})
export default class OverviewCarousel extends Vue {
  domainModule = getModule(DomainModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  navigationModule = getModule(NavigationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly items!: IOverviewCarouselItem[]
  @Prop() readonly loading?: boolean
  @Prop() readonly type!: CarouselType
  @Prop() readonly selectedRecentsTab?: CarouselRecentsTab

  @Ref() readonly carouselRef!: HTMLElement

  hovering = false
  scrollLeft = false
  scrollRight = false

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get sidebarExpanded () {
    return this.navigationModule.expanded
  }

  get navigationItems (): NavigationItem[] {
    switch (this.type) {
      case 'pinned':
      case 'recent':
        return [
          {
            icon: 'fad-sitemap',
            text: 'View all diagrams',
            to: {
              name: this.currentShareLink ? 'share-diagrams' : 'diagrams',
              params: {
                landscapeId: this.currentLandscapeId || '',
                versionId: this.currentVersionId
              },
              query: {
                domain: this.currentDomainHandleId || undefined
              }
            }
          },
          {
            icon: 'fad-shuffle',
            text: 'View all flows',
            to: {
              name: this.currentShareLink ? 'share-flows' : 'flows',
              params: {
                landscapeId: this.currentLandscapeId || '',
                versionId: this.currentVersionId
              },
              query: {
                domain: this.currentDomainHandleId || undefined
              }
            }
          }
        ]
      default:
        return []
    }
  }

  mounted () {
    this.carouselRef.addEventListener('scroll', this.updateScrollButtons)
    this.updateScrollButtons()
  }

  destroyed () {
    this.carouselRef?.removeEventListener('scroll', this.updateScrollButtons)
  }

  getButtonStyle (direction: 'left' | 'right') {
    if (this.loading) {
      return {
        cursor: 'default',
        opacity: '0'
      }
    }

    const scroll = direction === 'left' ? this.scrollLeft : this.scrollRight
    return {
      cursor: scroll ? 'pointer' : 'default',
      opacity: scroll && this.hovering ? '0.8' : '0'
    }
  }

  handleMoveCarousel (direction: string) {
    const scrollAmount = 1000
    this.carouselRef.scrollLeft += direction === 'left' ? -scrollAmount : scrollAmount
    this.updateScrollButtons()
  }

  updateScrollButtons () {
    const { scrollLeft, scrollWidth, clientWidth } = this.carouselRef
    this.scrollLeft = scrollLeft > 0
    this.scrollRight = scrollLeft + clientWidth < scrollWidth
  }

  mouseEnter () {
    this.hovering = true
    this.updateScrollButtons()
  }

  mouseLeave () {
    this.hovering = false
  }
}

