import { Diagram } from '@icepanel/platform-api-client'

export const isDiagramEmpty = (diagram: Diagram): boolean => {
  // Diagrams with comments are not considered empty
  if (diagram.commentCount > 0) {
    return false
  }

  // Diagrams with objects are not considered empty
  if (diagram.objectCount.box > 0) {
    return false
  }

  // Diagrams with only one area (and nothing else) are considered empty
  if (diagram.objectCount.area > 1) {
    return false
  }

  return true
}
